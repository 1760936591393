import baseHttp from "@store/api.js";

const state = () => ({
    updateModalValue: null,
    item: null,
    updatingActionApi: 'update',
    updatingText: null,
    pageData: [],
    params: [],
});

const getters = {
    getPageData: (state) => state.pageData,
    getParams: (state) => state.params,
};

const actions = {
    async fetchPageData({commit}, params) {
        const res = await baseHttp.get('cookie-settings/fetch', {
            params
        });
        commit('SET_PAGE_DATA', res.data);

        return res.data;
    },

    async fetchParams({commit}, params) {
        const res = await baseHttp.get('cookie-settings/fetch-params', {params});
        commit('SET_PARAMS', res.data);
    },

    async update({}, params) {
        return await baseHttp.put(`cookie-settings/update`, params);
    },
};

const mutations = {
    SET_PAGE_DATA(state, data) {
        state.pageData = data;
    },
    SET_PARAMS(state, params) {
        state.params = params;
    },
};

const cookieSettings = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default cookieSettings;
