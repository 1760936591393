import baseHttp from "@store/api.js";

const state = () => ({
    params: [],
});

const getters = {
    getParams: (state) => state.params,
};

const actions = {
    async fetchParams({commit}, params) {
        const res = await baseHttp.get('general-info/fetch', {params});
        commit('SET_PARAMS', res.data);
    },
};

const mutations = {
    SET_PARAMS(state, params) {
        state.params = params;
    },
};

const general = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

export default general;

